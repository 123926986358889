/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component, useEffect } from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import { SnackbarProvider } from "notistack"

import "./layout.css"
import {
  Typography,
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core"

const ImageCredits = () => {
  return (
    <List dense>
      <ListItem>
        <ListItemText>
          <div>
            <strong>contact us</strong> Icons made by{" "}
            <a
              href="https://www.flaticon.com/authors/pixel-perfect"
              title="Pixel perfect"
            >
              Pixel perfect
            </a>
{" "}
            from
{" "}
            <a href="https://www.flaticon.com/" title="Flaticon">
              www.flaticon.com
            </a>
          </div>
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <div>
            <strong>tools & approach</strong> Icons made by{" "}
            <a href="https://icons8.com" title="icons8">
              icons8
            </a>
{" "}
            from
{" "}
            <a href="https://icons8.com/animated-icons" title="Icons8">
              https://icons8.com
            </a>
          </div>
        </ListItemText>
      </ListItem>
    </List>
  )
}

const Layout = ({ children }) => {
  let theme = createMuiTheme({
    typography: {
      fontFamily: "Muli",
    },
  })

  theme = responsiveFontSizes(theme)

  const [open, setOpen] = React.useState(false)
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        <div
          style={{
            margin: `0 auto`,
            fontFamily: "Muli",
          }}
        >
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <main>{children}</main>
          </SnackbarProvider>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Typography variant="overline">
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              © {new Date().getFullYear()}, Chainworks Digital Private Limited
            </Typography>
            <Button color="primary" size="small" onClick={() => setOpen(true)}>
              Image Credits
            </Button>
            <Dialog
              fullScreen={fullScreen}
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">
                Image Credits
              </DialogTitle>
              <DialogContent>
                <ImageCredits />
              </DialogContent>
            </Dialog>
          </Grid>
        </div>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
